.content {
    .content-holder {
        display: flex;
    }

    .main {
        flex: 1;
        padding: 40px 40px 40px 0;

        .title {
            margin: 40px 0 0;
        }

        .children {
            margin: 40px 0 0;

            h2 {
                margin: 40px 0 16px;
            }

            h3 {
                margin: 40px 0 16px;
            }

            ul {
                li {
                    &:not(:first-child) {
                        margin: 24px 0 0;
                    }
                }
            }

            ol {
                li {
                    &:not(:first-child) {
                        margin: 32px 0 0;
                    }
                }
            }

            :global(.split) {
                margin: 60px 0 32px;
                height: 1px;
                background: var(--color-grey-platinum);
            }

            :global(.image) {
                height: 260px;
                box-shadow: var(--box-shadow-card);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                margin: 32px 0;
            }
        }
    }
}

@media(max-width: 768px) {
    .content {
        .main {
            padding: 32px 0;

            .children {
                :global(.image) {
                    height: 180px;
                }
            }
        }
    }
}
