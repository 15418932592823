.button {
    width: 24px;
    color: var(--color-grey-mild);
}

.drawer {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: var(--color-blue);
    color: var(--color-white);

    .drawer-top {
        display: flex;
        justify-content: space-between;
        padding: 16px;

        .drawer-social {
            display: flex;
    
            .drawer-social-item {
                width: 20px;
                height: 20px;

                &:not(:last-child) {
                    margin: 0 16px 0 0;
                }
            }
        }

        .drawer-close {
            width: 20px;
            height: 20px;
        }
    }

    .drawer-content {
        flex: 1;
        overflow-y: scroll;
        padding: 16px;
    }

    .menu {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .item {
            &:not(:last-child) {
                border-bottom: 1px solid #fff4;
            }

            .item-label {
                .item-label-holder {
                    padding: 24px 8px;
                    margin-left: -8px;
                    display: flex;
                    justify-content: space-between;
                    position: relative;

                    .item-label-text {
                        font-weight: bold;
                    }

                    .item-label-pointer {
                        color: var(--color-grey-platinum);
                        width: 8px;
                        margin: 0 8px 0 0;
                    }
                }
            }

            .item-list {
                .item-list-item {
                    position: relative;
                    
                    &:first-child {
                        margin: -8px 0 0;
                    }

                    &:not(:last-child) {
                        border-bottom: 1px dotted #fff2;
                    }

                    .item-list-item-label {
                        padding: 16px 8px;
                        font-size: 14px;
                    }
                }
            }

            .item-list-holder {
                overflow-y: hidden;
                transition-duration: .5s;
                transition-timing-function: ease-in-out;
            }
        }
    }
}
