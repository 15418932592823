.container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    border-radius: 4px;
    background: var(--color-blue);
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    transition: all ease .2s;
    border: 1px solid transparent;

    &:hover {
        opacity: .9;
    }

    &.is-loading {
        pointer-events: none;
    }

    &.disabled {
        opacity: .8;
        pointer-events: none;
    }

    .icon {
        width: 16px;
        height: 16px;
    }

    .icon + .value {
        margin: 0 0 0 16px;
    }

    .value {
        display: flex;
        align-items: center;

        .value-icon {
            width: 16px;
            height: 16px;
            margin: 0 0 0 8px;
        }
    }

    .loading {
        position: absolute;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border-radius: 4px;
        top: -1px;
        left: -1px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--gradient-base);
    }

    &.theme-negative {
        background: var(--color-white);
        border: 1px solid var(--color-white);
        color: var(--color-blue);
        transition: opacity ease .2s;

        &:hover {
            opacity: .8;
        }

        .loading {
            background: var(--color-white);
            color: var(--color-blue);
        }
    }

    &.theme-dark {
        background: var(--color-blue-dark);

        .loading {
            background: var(--color-blue-dark);
        }
    }

    &.theme-green {
        background: var(--color-green-accent);
    }

    &.theme-shallow {
        background: transparent;
        border: 1px solid var(--color-grey-mild);
        color: var(--color-grey-dark);
        transition: opacity ease .2s;

        &:hover {
            opacity: .6;
        }
    }
}
