.content {
    padding: 32px 0;
    background: var(--color-grey-black);
    color: var(--color-white);
    font-size: 14px;
    letter-spacing: .25px;

    .content-holder {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .social {
        display: flex;

        .social-item {
            &:not(:last-child) {
                margin: 0 16px 0 0;
            }

            .social-item-icon {
                width: 32px;
                height: 32px;
                transition: all ease .2s;

                &:hover {
                    opacity: .8;
                }
            }
        }
    }

    .info {
        flex: 1;
        padding: 0 120px 0 0;
        display: flex;
        justify-content: space-between;

        .block {
            &:not(:first-child) {
                margin: 32px 0 0;
            }

            .block-title {
                font-weight: bold;
            }

            .block-content {
                margin: 4px 0 0;
                color: var(--color-grey-light);
                line-height: 1.71;

                a:hover {
                    border-bottom: 1px dotted;
                }
            }
        }

        .main {
            .logo {
                span {
                    font-size: 20px;
                    font-weight: bold;
                    text-transform: uppercase;
                    letter-spacing: -.25px;
                }
            }

            .address {
                margin: 16px 0 0;
                line-height: 1.71;
                color: var(--color-grey-light);
            }

            .split {
                width: 80px;
                height: 2px;
                background: var(--color-white);
                margin: 24px 0;
            }

            .ufmg {
                width: 67px;
                height: 25px;
                background: url(/img/global/ufmg-white.svg) center no-repeat;
            }
        }
    }
}

.bottom {
    padding: 16px 0;
    background: var(--color-black);
    
    .bottom-holder {
        display: flex;
        justify-content: flex-end;
    }

    .vbases {
        margin: 0 -8px 0 0;

        .vbases-logo {
            width: 70px;
            height: 30px;
            background: url(/img/global/vbases.svg) 50% no-repeat;
            background-size: 80%;
            transition: opacity ease .2s;

            &:hover {
                opacity: .8;
            }
        }
    }
}

@media(max-width: 768px) {
    .content {
        .content-holder {
            display: block;
        }

        .social {
            justify-content: center;
        }

        .info {
            margin: 32px 0 0;
            padding: 32px 0 0;
            display: block;
            border-top: 1px solid var(--color-grey-mild);

            .contacts,
            .links {
                margin: 32px 0 0;
            }
        }
    }
}
