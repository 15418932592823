.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 376px);
}

.loading {
    color: var(--color-blue);
}

@media(max-width: 768px) {
    .container {
        min-height: 300px;
    }
}
