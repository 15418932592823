.container {
    .list {
        list-style: none;
        padding: 0;
        margin: 0;

        display: flex;

        .item {
            font-size: 14px;
            letter-spacing: .25px;
            cursor: default;
            position: relative;

            .item-home {
                width: 16px;
                height: 16px;
                color: var(--color-text-base);
            }

            &:not(:first-child) {
                margin: 0 0 0 20px;
            }

            &:not(:last-child) {
                &::after {
                    content: '\00BB';
                    position: absolute;
                    right: -14px;
                    top: -1px;
                    pointer-events: none;
                }
            }
        }
    }
}
