.container {
    width: 336px;
    padding: 56px 48px;
    background: var(--color-grey-plain);
    position: relative;
    min-height: calc(100vh - 376px);

    .item {
        position: sticky;
        top: 100px;

        .title {
            font-size: 14px;
            letter-spacing: .25px;
            color: var(--color-grey-mild);
        }

        .list {
            margin: 8px 0 0;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
            }

            li {
                padding: 8px 0;
                a {
                    color: var(--color-blue);

                    &:hover {
                        color: var(--color-blue-light);
                    }
                }
            }
        }
    }
}

@media(max-width: 768px) {
    .container {
        display: none;
    }
}
