.container {
    display: flex;
    flex-direction: column;
    background: var(--white);
    min-height: 100vh;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;

    & > div {
        flex: 1;
    }
}
