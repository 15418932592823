.container {
    background-position: center;
    background-size: cover;
    position: relative;
    height: 350px;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(to bottom, #000a 0%, #0000 60%);
    }
}

@media(max-width: 768px) {
    .container {
        height: 200px;
    }
}
