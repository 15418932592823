.container {
    ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &.just-clicked {
        .item {
            &:hover {
                .item-list {
                    display: none;
                }
            }
        }
    }

    .item {
        position: relative;

        &:hover,
        &.current {
            .item-label {
                color: var(--color-blue-light);

                .item-label-icon {
                    color: var(--color-blue-light);
                }
            }
        }

        .item-label {
            font-size: 16px;
            line-height: 24px;
            color: var(--color-grey-mild);
            letter-spacing: .5px;
            
            .item-label-holder {
                display: flex;
                align-items: center;
                padding: 4px 20px;
            }

            .item-label-icon {
                width: 7px;
                margin: 4px 0 0 8px;
                color: var(--color-grey-light);
            }
        }

        .item-list {
            display: none;
            position: absolute;
            top: 100%;
            left: 20px;
            min-width: 200px;
            max-width: 250px;
            background: var(--color-white);
            box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2);

            .item-list-item {

                &.current {
                    .item-list-item-label {
                        color: var(--color-blue-light);
                    }
                }

                .item-list-item-label {
                    padding: 8px 16px;

                    &:hover {
                        background: var(--color-grey-plain);
                    }
                }

                &:first-child {
                    .item-list-item-label {
                        padding-top: 16px;
                    }
                }

                &:last-child {
                    .item-list-item-label {
                        padding-bottom: 16px;
                    }
                }
            }
        }

        &:hover {
            .item-list {
                display: block;
            }
        }
    }
}
