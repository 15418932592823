.height-fix {
    height: 75px;
}

.content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background: var(--color-white);
    padding: 8px 0;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    .content-holder {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all ease .2s;
        padding: 8px;
        position: relative;
        color: var(--color-blue);

        &:hover {
            opacity: .8;
        }

        .logo-image {
            width: 32px;
            height: 32px;
            background: url(/img/global/ufmg-m-red.svg) center no-repeat;
        }

        .logo-split {
            width: 2px;
            height: 32px;
            background: var(--color-grey-dark);
            margin: 0 16px;
        }

        .logo-text {
            color: var(--color-grey-dark);
            font-size: 14px;
            letter-spacing: .25px;

            span {
                font-size: 20px;
                letter-spacing: -.25px;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }

    .menu-desktop {
        display: block;
    }

    .menu-mobile {
        display: none;
    }
}

@media(max-width: 768px) {
    .height-fix {
        height: 51px;
    }

    .content {
        padding: 0;

        .logo {
            margin-left: -8px;

            .logo-image {
                width: 20px;
                height: 20px;
            }

            .logo-split {
                width: 1px;
                height: 20px;
                margin: 0 8px;
            }

            .logo-text {
                font-size: 12px;

                span {
                    font-size: 16px;
                }
            }
        }

        .menu-desktop {
            display: none;
        }
    
        .menu-mobile {
            display: block;
        }
    }
}
