.button-ver-mais {
    display: flex;
    align-items: center;
    color: var(--color-blue);
    position: relative;
    padding: 4px 4px 4px 8px;
    border-radius: 4px;
    margin-right: -4px;
    cursor: pointer;

    .button-ver-mais-text {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: .5px;
    }

    .button-ver-mais-icon {
        width: 20px;
        margin: 0 0 0 12px;
    }
}

.slider {
    & :global(.slick-arrow) {
        display: none !important;
    }

    .slider-item {
        height: 550px;
        background-size: cover;
        background-position: center;

        &.tradicao {
            background-image: url(/img/pages/home/slider/tradicao-desktop.jpg);
        }

        &.problemas-complexos {
            background-image: url(/img/pages/home/slider/problemas-complexos-desktop.jpg);
        }
    }

    .slider-item-holder {
        padding: 200px 0 0;
    }

    .slider-item-content {
        color: var(--color-white);
        max-width: 80%;
        text-shadow: 2px 2px 6px #0008;
    }

    .slider-item-text {
        font-size: 60px;
        font-weight: bold;
        line-height: .97;
    }

    .slider-item-subtext {
        margin: 16px 0 0;
        font-size: 20px;
        letter-spacing: .25px;
    }
}

.content {
    .content-holder {
        display: flex;

        .main {
            padding: 48px 48px 48px 0;
            flex: 1;
        }
    }
}

.es {
    display: flex;
    align-items: center;

    .es-image {
        width: 234px;
        height: 322px;
        margin: 0 48px 0 0;
        background: url(/img/pages/home/es/image-desktop.jpg) center no-repeat;
        background-size: cover;
        box-shadow: var(--box-shadow-card);
    }

    .es-main {
        flex: 1;

        .es-text {
            margin: 24px 0 0;
        }
    }
}

.numbers {
    padding: 64px 0;
    display: flex;
    justify-content: space-around;

    .numbers-item {
        text-align: center;
        color: var(--color-blue);
        flex: 1;

        .numbers-item-prefix {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 14px;
        }
    
        .numbers-item-number {
            font-size: 48px;
            font-weight: bold;
            letter-spacing: .38px;
            line-height: 48px;
        }

        .numbers-item-text {
            margin: 8px 0 0;
        }

        .numbers-item-suffix {
            margin: 4px 0 0;
            font-size: 12px;
            color: var(--color-grey-mild);
        }
    }
}

.nucleos {
    .nucleos-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nucleos-list {
        margin: 40px 0 0;

        .nucleos-list-track {
            display: flex;
        }

        .nucleos-list-item {
            &:not(:last-child) {
                margin: 0 32px 0 0;
            }

            .nucleos-list-item-image {
                height: 140px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                box-shadow: var(--box-shadow-card);
            }

            .nucleos-list-item-title {
                margin: 24px 0 0;
                font-weight: bold;
                font-size: 20px;
                color: var(--color-grey-dark);
                height: 50px;
            }

            .nucleos-list-item-text {
                margin: 16px 0 0;
            }

            &.eletronica {
                .nucleos-list-item-image {
                    background-image: url(/img/pages/curso/nucleos-conceituais/eletronica-mobile.jpg);
                }
            }

            &.simulacao {
                .nucleos-list-item-image {
                    background-image: url(/img/pages/curso/nucleos-conceituais/simulacao-mobile.jpg);
                }
            }

            &.modelagem {
                .nucleos-list-item-image {
                    background-image: url(/img/pages/curso/nucleos-conceituais/modelagem-mobile.jpg);
                }
            }
        }
    }

    .nucleos-button {
        display: none;
        margin: 24px 0 0;
        justify-content: flex-end;
    }
}

.grade {
    margin: 64px 0;
    padding: 32px;
    background: url(/img/pages/curso/grade-curricular/hero-desktop.jpg);
    border-radius: 8px;
    color: var(--color-white);

    .grade-title {
        max-width: 70%;

        h1 {
            color: var(--color-white);
        }
    }

    .grade-text {
        margin: 16px 0 0;
        max-width: 50%;

        :global(.text) {
            color: var(--color-white);
            text-shadow: 2px 2px 6px rgba(0,0,0,.5);
        }
    }

    .grade-button {
        margin: 32px 0 0;
    }
}

.internacionais {
    .internacionais-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .internacionais-list {
        margin: 40px 0 0;

        .internacionais-list-track {
            display: flex;
        }

        .internacionais-list-item {
            &:not(:last-child) {
                margin: 0 32px 0 0;
            }

            .internacionais-list-item-image {
                height: 140px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                box-shadow: var(--box-shadow-card);
            }

            .internacionais-list-item-title {
                margin: 24px 0 0;
                font-weight: bold;
                font-size: 20px;
                color: var(--color-grey-dark);
            }

            .internacionais-list-item-location {
                font-size: 14px;
                letter-spacing: .25px;
                color: var(--grey-mild);
            }

            .internacionais-list-item-text {
                margin: 16px 0 0;
            }

            &.polytechnique { .internacionais-list-item-image { background-image: url(/img/pages/sobre/cursos-internacionais/polytechnique-mobile.jpg); } }
            &.sheffield { .internacionais-list-item-image { background-image: url(/img/pages/sobre/cursos-internacionais/sheffield-mobile.jpg); } }
            &.mit { .internacionais-list-item-image { background-image: url(/img/pages/sobre/cursos-internacionais/mit-mobile.jpg); } }
        }
    }

    .internacionais-button {
        display: none;
        margin: 24px 0 0;
        justify-content: flex-end;
    }
}

@media(max-width: 768px) {
    .slider {
        .slider-item {
            height: 300px;

            &.tradicao {
                background-image: url(/img/pages/home/slider/tradicao-mobile.jpg);
            }
    
            &.problemas-complexos {
                background-image: url(/img/pages/home/slider/problemas-complexos-mobile.jpg);
            }
        }

        .slider-item-holder {
            padding: 60px 0 0;
        }

        .slider-item-content {
            max-width: unset;
        }

        .slider-item-text {
            font-size: 24px;
            line-height: 1.2;
        }

        .slider-item-subtext {
            font-size: 16px;
        }
    }

    .content {
        .content-holder {
            display: block;

            .main {
                padding: 32px 0;
            }
        }
    }

    .es {
        display: block;

        .es-image {
            width: 100%;
            height: 120px;
        }

        .es-main {
            margin: 24px 0 0;
        }
    }

    .numbers {
        display: block;

        .numbers-item {
            &:not(:first-child) {
                margin: 32px 0 0;
            }
        }
    }

    .nucleos {
        .nucleos-title {
            .nucleos-title-button {
                display: none;
            }
        }

        .nucleos-list {
            overflow-x: scroll;
            width: calc(100% + 32px);
            margin-left: -16px;

            .nucleos-list-track {
                width: 200%;
                padding: 0 16px;
            }

            .nucleos-list-item {
                .nucleos-list-item-title {
                    font-size: 16px;
                    height: 40px;
                }
            }
        }

        .nucleos-button {
            display: flex;
        }
    }

    .grade {
        width: calc(100% + 32px);
        margin-left: -16px;
        border-radius: 0;
        padding: 24px 16px;
        background: url(/img/pages/curso/grade-curricular/hero-mobile.jpg) center;
        background-size: cover;
        position: relative;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #0007;
            z-index: 2;
        }

        & > * {
            position: relative;
            z-index: 3;
        }

        .grade-title {
            max-width: unset;
        }

        .grade-text {
            max-width: 80%;
        }
    }

    .internacionais {
        .internacionais-title {
            .internacionais-title-button {
                display: none;
            }
        }

        .internacionais-list {
            overflow-x: scroll;
            width: calc(100% + 32px);
            margin-left: -16px;

            .internacionais-list-track {
                width: 200%;
                padding: 0 16px;
            }

            .internacionais-list-item {
                .internacionais-list-item-title {
                    font-size: 16px;
                }
            }
        }

        .internacionais-button {
            display: flex;
        }
    }
}
